
const $ = jQuery;
export const getBoxValue = object => Object.values(object).join(" ");

export const checkDirection = (val) => {

    if (val === 'center center') {
        return { moveFromEdge: '0px' }
    } else {
        return { moveFromEdge: "50%" }
    }
}

export const tabController = () => {
    setTimeout(() => {
        const panelBodies = document.querySelectorAll('.components-panel__body-title button');
        panelBodies.forEach(item => {
            item.addEventListener('click', clickEveryItem);
        });

        function clickEveryItem() {
            this.removeEventListener('click', clickEveryItem);
            panelBodies.forEach(item => {
                if (item.getAttribute('aria-expanded') === 'true' && !item.isEqualNode(this)) {
                    item.click();
                }
            });
            setTimeout(() => {
                this.addEventListener('click', clickEveryItem);
            }, 500);
        }
    }, 500);
};

// slide on mouse wheel
export function whileEvent(e) {
    if (e.originalEvent.wheelDelta / 120 > 0) {
        $(this).carousel('next');
    }
    else {
        $(this).carousel('prev');
    }
}

// Mouse Drag slide 
var oldx = 0;
export function slideStart(event) {
    oldx = event.pageX;
}

export function slideEnd(event) {
    if (event.pageX < oldx) {
        jQuery(this).carousel('next');
    }
    else if (event.pageX > oldx) {
        jQuery(this).carousel('prev');
    }
}